jQuery(document).ready(function ($) {
    function cleanLastTweet($feed) {
        var $last = $feed.find('li.current');
        var $current = $feed.find('li.next');
        $last.removeClass('current').addClass('inactive');
        $current.addClass('current').removeClass('next');
        setTimeout(changeTweet, 10000, $feed);
    }

    function changeTweet($feed) {
        var $current = $feed.find('li.current');
        var $next = null;
        if ($current.length > 0) {
            $next = $current.next();
        }
        if ($next.length === 0) {
            $next = $feed.find('li:first-child');
        }
        $next.removeClass('inactive').addClass('next');
        setTimeout(cleanLastTweet, 2000, $feed);
    }

    function setTwitterFeedHeight($feed) {
        var maxHeight = 0;
        var $statii = $feed.find('li > .status');
        $statii.height('auto');
        $statii.each(function() {
            var $this = $(this);
            var height = $this.height();
            if (height > maxHeight) {
                maxHeight = height;
            }
        });
        $statii.height(maxHeight);
        $feed.find('ul').height(maxHeight);
    }

    $('.twitter-feed').each(function() {
        var $this = $(this);
        $this.find('li:first-child').addClass('current');
        $this.find('li:not(.current)').addClass('inactive');
        setTwitterFeedHeight($this);
        setTimeout(changeTweet, 12000, $this);
    });

    // TODO: resize twitter on browser resize

    //show hide team biogs on hover
    $('.team-member .bio').hide(); //hide bios to start with

    //show bio on image click
    $('.team-member img').click(function() {
        $(this).toggle();
        $(this).next().toggle();
    });

    //hide bio on bio click
    $('.team-member .bio').click(function() {
        $(this).hide();
        $(this).prev().toggle();
    });

    //show/hide bio/image on name click
    $('.team-member h2').click(function() {
        $(this).next().toggle(); //hide img
        $(this).next().next().toggle(); //show bio
    });
}(jQuery));