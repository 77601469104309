jQuery(document).ready(function () {
    var slideshowTransitions = [
        //Swing Outside in Stairs
        //{ $Duration: 1200, x: 0.2, y: -0.1, $Delay: 20, $Cols: 8, $Rows: 8, $Clip: 15, $During: { $Left: [0.3, 0.7], $Top: [0.3, 0.7] }, $Formation: $JssorSlideshowFormations$.$FormationStraightStairs, $Assembly: 260, $Easing: { $Left: $JssorEasing$.$EaseInWave, $Top: $JssorEasing$.$EaseInWave, $Clip: $JssorEasing$.$EaseOutQuad }, $Outside: true, $Round: { $Left: 1.3, $Top: 2.5 } }

        //Dodge Dance Outside out Stairs
        //, { $Duration: 1500, x: 0.3, y: -0.3, $Delay: 20, $Cols: 8, $Rows: 8, $Clip: 15, $During: { $Left: [0.1, 0.9], $Top: [0.1, 0.9] }, $SlideOut: true, $Formation: $JssorSlideshowFormations$.$FormationStraightStairs, $Assembly: 260, $Easing: { $Left: $JssorEasing$.$EaseInJump, $Top: $JssorEasing$.$EaseInJump, $Clip: $JssorEasing$.$EaseOutQuad }, $Outside: true, $Round: { $Left: 0.8, $Top: 2.5 } }

        //Dodge Pet Outside in Stairs
        //, { $Duration: 1500, x: 0.2, y: -0.1, $Delay: 20, $Cols: 8, $Rows: 8, $Clip: 15, $During: { $Left: [0.3, 0.7], $Top: [0.3, 0.7] }, $Formation: $JssorSlideshowFormations$.$FormationStraightStairs, $Assembly: 260, $Easing: { $Left: $JssorEasing$.$EaseInWave, $Top: $JssorEasing$.$EaseInWave, $Clip: $JssorEasing$.$EaseOutQuad }, $Outside: true, $Round: { $Left: 0.8, $Top: 2.5 } }

        //Dodge Dance Outside in Random
        //, { $Duration: 1500, x: 0.3, y: -0.3, $Delay: 80, $Cols: 8, $Rows: 8, $Clip: 15, $During: { $Left: [0.3, 0.7], $Top: [0.3, 0.7] }, $Easing: { $Left: $JssorEasing$.$EaseInJump, $Top: $JssorEasing$.$EaseInJump, $Clip: $JssorEasing$.$EaseOutQuad }, $Outside: true, $Round: { $Left: 0.8, $Top: 2.5 } }

        //Flutter out Wind
        //, { $Duration: 1800, x: 1, y: 0.2, $Delay: 30, $Cols: 10, $Rows: 7, $Clip: 15, $During: { $Left: [0.3, 0.7], $Top: [0.3, 0.7] }, $SlideOut: true, $Reverse: true, $Formation: $JssorSlideshowFormations$.$FormationStraightStairs, $Assembly: 2050, $Easing: { $Left: $JssorEasing$.$EaseInOutSine, $Top: $JssorEasing$.$EaseOutWave, $Clip: $JssorEasing$.$EaseInOutQuad }, $Outside: true, $Round: { $Top: 1.3 } }

        //Collapse Stairs
        //, { $Duration: 1200, $Delay: 30, $Cols: 8, $Rows: 8, $Clip: 15, $SlideOut: true, $Formation: $JssorSlideshowFormations$.$FormationStraightStairs, $Assembly: 2049, $Easing: $JssorEasing$.$EaseOutQuad }

        //Collapse Random
        //, { $Duration: 1000, $Delay: 80, $Cols: 8, $Rows: 8, $Clip: 15, $SlideOut: true, $Easing: $JssorEasing$.$EaseOutQuad }

        //Vertical Chess Stripe
        //, { $Duration: 1000, y: -1, $Cols: 12, $Formation: $JssorSlideshowFormations$.$FormationStraight, $ChessMode: { $Column: 12 } }

        //Extrude out Stripe
        //, { $Duration: 1000, x: -0.2, $Delay: 40, $Cols: 12, $SlideOut: true, $Formation: $JssorSlideshowFormations$.$FormationStraight, $Assembly: 260, $Easing: { $Left: $JssorEasing$.$EaseInOutExpo, $Opacity: $JssorEasing$.$EaseInOutQuad }, $Opacity: 2, $Outside: true, $Round: { $Top: 0.5 } }

        //Dominoes Stripe
        //, { $Duration: 2000, y: -1, $Delay: 60, $Cols: 15, $SlideOut: true, $Formation: $JssorSlideshowFormations$.$FormationStraight, $Easing: $JssorEasing$.$EaseOutJump, $Round: { $Top: 1.5 } }

        // Slide over from right
        { $Duration: 700, $Opacity: 2, $Brother: { $Duration: 1000, $Opacity: 2 } }

        // Fade
        , { $Duration: 1200, $Opacity: 2 }

        // Fade in bars from left
        //, { $Duration: 800, $Delay: 30, $Cols: 8, $Rows: 8, $Formation: $JssorSlideshowFormations$.$FormationStraightStairs, $Assembly: 2050, $Opacity: 2 }

        // Wipe in blocks diagonally from bottom left
        //, { $Duration: 1200, x: 0.2, y: -0.1, $Delay: 20, $Cols: 8, $Rows: 8, $Clip: 15 }

        // Wipe in blocks diagonally randomly from bottom left
        //, { $Duration: 1200, x: 0.2, y: -0.1, $Delay: 80, $Cols: 8, $Rows: 8, $Clip: 15 }

        // Fade in bars from right
        //, { $Duration: 600, $Delay: 100, $Cols: 10, $Formation: $JssorSlideshowFormations$.$FormationStraight, $Opacity: 2 }

        // Fade in random horizontal bars
        //, { $Duration: 600, $Delay: 80, $Rows: 6, $Opacity: 2 }

        // Vertical blind
        //, { $Duration: 1000, $Cols: 8, $Clip: 1 }

        // Vertical roller blind from right
        //, { $Duration: 400, $Delay: 100, $Cols: 10, $Clip: 2, $Formation: $JssorSlideshowFormations$.$FormationStraight }
    ];

    var captionTransitions = [{ $Duration: 900, $Opacity: 2 }];

    var options = {
        $FillMode: 1,
        $AutoPlay: true,                                    //[Optional] Whether to auto play, to enable slideshow, this option must be set to true, default value is false
        $AutoPlayInterval: 1500,                            //[Optional] Interval (in milliseconds) to go for next slide since the previous stopped if the slider is auto playing, default value is 3000
        $PauseOnHover: 3,                                //[Optional] Whether to pause when mouse over if a slider is auto playing, 0 no pause, 1 pause for desktop, 2 pause for touch device, 3 pause for desktop and touch device, default value is 3

        $DragOrientation: 3,                                //[Optional] Orientation to drag slide, 0 no drag, 1 horizental, 2 vertical, 3 either, default value is 1 (Note that the $DragOrientation should be the same as $PlayOrientation when $DisplayPieces is greater than 1, or parking position is not 0)
        $ArrowKeyNavigation: true,   			            //[Optional] Allows keyboard (arrow key) navigation or not, default value is false
        $SlideDuration: 1200,                                //Specifies default duration (swipe) for slide in milliseconds

        $SlideshowOptions: {                                //[Optional] Options to specify and enable slideshow or not
            $Class: $JssorSlideshowRunner$,                 //[Required] Class to create instance of slideshow
            $Transitions: slideshowTransitions,            //[Required] An array of slideshow transitions to play slideshow
            $TransitionsOrder: 0,                           //[Optional] The way to choose transition to play slide, 1 Sequence, 0 Random
            $ShowLink: true                                    //[Optional] Whether to bring slide link on top of the slider when slideshow is running, default value is false
        },

        $ArrowNavigatorOptions: {                           //[Optional] Options to specify and enable direction navigator or not
            $Class: $JssorArrowNavigator$,                  //[Requried] Class to create direction navigator instance
            $ChanceToShow: 1                               //[Required] 0 Never, 1 Mouse Over, 2 Always
        },

        $CaptionSliderOptions: {
            $Class: $JssorCaptionSlider$,					//[Required] Class to create instance of caption
            $CaptionTransitions: captionTransitions			//[Required] An array of caption transitions to animate captions
        },

        $ThumbnailNavigatorOptions: {                       //[Optional] Options to specify and enable thumbnail navigator or not
            $Class: $JssorThumbnailNavigator$,              //[Required] Class to create thumbnail navigator instance
            $ChanceToShow: 2,                               //[Required] 0 Never, 1 Mouse Over, 2 Always

            $ActionMode: 1,                                 //[Optional] 0 None, 1 act by click, 2 act by mouse hover, 3 both, default value is 1
            $SpacingX: 6,                                   //[Optional] Horizontal space between each thumbnail in pixel, default value is 0
            $DisplayPieces: 6,                             //[Optional] Number of pieces to display, default value is 1
            $ParkingPosition: 360                          //[Optional] The offset position to park thumbnail
        }
    };

    $('.gallery-cntr').each(function () {
        var $galCntr = $(this);

        var gallery = new $JssorSlider$($galCntr.attr('id'), options);

        function scaleGallery() {
            var parentWidth = $galCntr.parent().width();
            if (parentWidth) {
                gallery.$ScaleWidth(parentWidth);
            }
            else
                window.setTimeout(scaleGallery, 30);
        }
        //Scale slider after document ready
        scaleGallery();

        //Scale slider while window load/resize/orientationchange.
        $(window).bind("load", scaleGallery);
        $(window).bind("resize", scaleGallery);
        $(window).bind("orientationchange", scaleGallery);
    });
});